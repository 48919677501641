import * as React from 'react';
import { Link } from 'gatsby';

import { Layout, SEO, Hero } from '../components';
import { useGraphQL } from '../hooks';

function NotFoundPage() {
  const { notFoundHeroImage } = useGraphQL();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Hero
        image={notFoundHeroImage.childImageSharp.fluid}
        imgStyle={{ objectPosition: 'top' }}
      />
      <Copy />
    </Layout>
  );
}

function Copy() {
  return (
    <article className="relative w-full px-8 py-24 mx-auto overflow-hidden bg-white max-w-screen-2xl">
      <div className="text-center">
        <h1 className="heading-2">404: Page Not Found</h1>
        <p className="mt-4 font-medium text-teal">
          Sorry, the page you were looking for could not be found.
        </p>
        <div className="mt-8 text-center">
          <Link to="/" className="button">
            Return home
          </Link>
        </div>
      </div>
    </article>
  );
}

export default NotFoundPage;
